
import React  from 'react';
import { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import ApiService from './ApiService';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import imageFlashFluencyBanner from './images/FlashFluencyBanner.png';

const LoginScreen = () => {
    let navigate = useNavigate();
    const apiService = new ApiService();
    const { setUserId } = useUser();
    const [loggingIn, setLoggingIn] = useState(false);
    const [loggingInMessage, setLoggingInMessage] = useState('');


    const handleGoogleResponse = (response) => {
        const { credential } = response;
        const decoded = jwtDecode(credential);

        setLoggingIn(true); // Set loading to true when login starts
        setLoggingInMessage('Successfully logged in.  If this is your first time logging in please wait a few seconds while we initialize your personalized proficiency tracking vectors.');

        // Extract the email and unique Google ID
        const googleId = decoded.sub; // Google unique ID
        const email = decoded.email; // User's email

        console.log('Google ID:', googleId);
        console.log('Email:', email);

        apiService.get_or_create_user_from_google_unique_id(googleId, email)
            .then(output => {
                const user_id = output['_id'];
                console.log('user_id: ', user_id)
                setUserId(user_id);
                navigate('/platform');
            })
            .catch(error => {
                console.error('Error logging in:', error);
                setLoggingIn(false); // Reset loading state in case of error
                setLoggingInMessage('There was an issue logging in. Please try again.');
            });
    };

        return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ marginBottom: '20px' }}>
                <img src={imageFlashFluencyBanner} alt="FlashFluency Banner" style={{ width: '400px', height: 'auto' }} />
            </div>
            {/* Conditionally render based on loading state */}
            {loggingIn ? (
                <div>
                    <p>{loggingInMessage}</p>
                </div>
            ) : (
                <div>
                    <div>
                        <GoogleLogin
                            // clientId={clientId}
                            onSuccess={credentialResponse => {
                                console.log('Login Successful:', credentialResponse);
                                handleGoogleResponse(credentialResponse);
                            }}
                            onError={() => {
                                console.log('Google Login Failed');
                                setLoggingInMessage('Google Login Failed.');
                            }}
                            style={{
                                padding: '12px 24px',
                                fontSize: '12px'
                            }}
                        />
                    </div>
                    
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        <p>
                            By logging in, you agree to our
                            <a 
                            href="/terms_of_service" 
                            className="text-blue-500 underline ml-1"
                            >
                            Terms of Service
                            </a>.
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginScreen;